import { client } from '../js/oss'

const dir = document.domain === 'www.xiaomeng.tech' ? 'prod' : 'local' // 判断是否为生产环境

function upload (file, iid = '0', success, cb) {
  const loading = window.$loading({ text: '0%' })
  var filename = file.name
  var index = filename.lastIndexOf('.')
  var suffix = filename.substring(index + 1, filename.length) // 获取文件后缀
  // 重构文件名 加上时间序列防止重名
  filename = dir + '/' + iid + '/0/' + new Date().getTime() + Math.floor(Math.random() * 150) + '.' + suffix
  client.multipartUpload(filename, file, {
    progress: p => {
      loading.setText((p * 100).toFixed() + '%')
    }
  }).then(function (result) {
    if (result.res.requestUrls) {
      loading.close()
      success && success(result.res.requestUrls[0].split('?')[0])
      cb && cb(result.res.requestUrls[0].split('?')[0])
    }
  }).catch(_ => { })
}

export default upload
